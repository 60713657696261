body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.badge {
  border-radius: 2000px;
  width: 8px;
  height: 8px;
  margin: 8px;
}
.badge.badge-big {
  width: 16px;
  height: 16px;
  margin: 16px;
}

pwa-camera-modal-instance {
  z-index: 1500;
}
